body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevents scrolling of the entire page */
}

.app-container {
  display: flex;
  height: 100vh; /* Takes up the full height of the viewport */
  overflow: hidden; /* Prevents scrolling of the content within the container */
}

.main-content {
  padding-left: 242px;
  padding-top: 300px;
  flex-grow: 1; /* Takes up the remaining width */
  background: #F5F6FA;
  padding: 20px;
  overflow-y: hidden; /* Adds scrolling within the content area if necessary */
}
